import React from 'react';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getAnalytics} from "firebase/analytics";
import {useAuthState} from "react-firebase-hooks/auth";

import './App.scss';
import {useRoutes} from "../routes";
import {store} from "../redux/Store";
import Loader from "../components/UI/Loader/Loader";
import {getMessaging, getToken} from "firebase/messaging";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const Firebase = initializeApp(firebaseConfig)


function requestPermission() {


    const messaging = getMessaging(Firebase);
    getToken(messaging, {
        vapidKey:
            "BAGSnXLkQpl-Fi0rWj8FAdjw52RlitauBffsc-r3HqI2tj7dc5asLCDR2tMEgU0BMd1EmaT8PRGpFR2FPqIB98Y",
    }).then((currentToken) => {
        if (currentToken) {
            //console.log(currentToken);
        } else {
            console.log("Can not get token");
        }
    });

}

requestPermission();

const analytics = getAnalytics(Firebase);

function App() {
    const routes = useRoutes()
    const auth = getAuth(Firebase);

    // const functions = getFunctions(getApp());
    // connectFunctionsEmulator(functions, "127.0.0.1", 5001);

    const [user, loading, error] = useAuthState(auth)


    if (loading) {
            return (
                <Loader/>
            );
    }

    return (
        <>
            <Provider store={store}>
                <BrowserRouter>
                    {routes}
                </BrowserRouter>
            </Provider>
        </>
  );
}

export default App;
