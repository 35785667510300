import React from 'react';
import {Button, Modal} from "react-bootstrap";

import "./ModalDeleteChat.scss"
import {AppDispatch, RootState} from "../../../redux/Store";
import {useDispatch, useSelector} from "react-redux";
import {Chat, deleteChatLocal, fetchUserChatId} from "../../../redux/AppSlice";

interface modalShowInterface {
    show: boolean,
    chatId: string | any,
    setShow: (value: boolean) => void,
}

const ModalDeleteChat = ({show, setShow, chatId}: modalShowInterface) => {
    const handleClose = (): void => setShow(false);

    const dispatch: AppDispatch = useDispatch();

    const chatsIds: any = useSelector(
        (state: RootState) => state.app.userChats
    );

    function findNextId(ids: Chat[], currentId: string) {
        const currentIndex = ids.findIndex((chat: Chat) => chat.id === currentId);

        if (currentIndex !== -1) {
            const nextIndex = (currentIndex + 1) % ids.length;
            return ids[nextIndex];
        }

        return -1;
    }

    const deleteChat = () => {
        chatId && dispatch(deleteChatLocal(chatId))

        const nextChat:Chat | any = findNextId(chatsIds, chatId);

        handleClose()
        dispatch(fetchUserChatId(nextChat.id))
    }

    return (
        <Modal className='ModalDeleteChat' show={show} centered={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Chat?</Modal.Title>
            </Modal.Header>
             <Modal.Body>
                <p className='text'>This chat will be <span>deleted</span></p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose}  type="submit" className='button-submit cancel' >
                    Cancel
                </Button>
                <Button onClick={deleteChat}   type="submit" className='button-submit delete' >
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalDeleteChat;
