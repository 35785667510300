import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {getFunctions} from "firebase/functions";

import "./ModalRegister.scss"
import {RootState} from "../../../redux/Store";

import {Firebase} from "../../../app/App";

interface modalShowInterface {
    show: boolean,
    setShow: (value: boolean) => void,
    setLoading:(value: boolean) => void,
}

const ModalRegister = ({show, setShow,setLoading}: modalShowInterface) => {
    const navigate = useNavigate();

    const [createUser] = useHttpsCallable(
        getFunctions(Firebase),
        'createUser'
    )

    const providerAuth = useSelector(
        (state: RootState) => state.app.provider
    );

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    const isValid = emailPattern.test(email);
    const submitRegister = async () => {
        const isValid = emailPattern.test(email);
        if (isValid && name.length > 3) {
            setLoading(true)
            setShow(false)
            await createUser({
                name: name,
                provider: providerAuth,
                email: email
            }).then((res) => {
                setLoading(false)
                navigate('/main')
            })
        }
    }

    const handleKeyPress = (event:any) => {
        if (event.key === 'Enter') {
            submitRegister();
        }
    };

    const handleClose = (): void => setShow(false);
    return (
        <Modal className='modal-register' show={show} centered={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form action="" method="post">
                    <div className="input-item">
                        <label htmlFor="idea">YOUR NAME</label>
                        <input type="text" value={name} onChange={(e) => setName(e.currentTarget.value)} required
                               placeholder="Enter your name*"/>
                    </div>
                    <div className="input-item">
                        <label htmlFor="idea">WORK EMAIL</label>
                        <input type="email" onKeyDown={handleKeyPress} value={email}
                               onChange={(e) => setEmail(e.currentTarget.value)} required
                               placeholder="Enter your email*"/>
                    </div>
                </form>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={submitRegister} disabled={!isValid || name.length < 3} type="submit"
                        className='button-submit'>
                    CREATE ACCOUNT
                </Button>
                <p className='terms-text'>By creating account you agree with our <a target="_blank" href="/privacy-policy.html">Privacy
                    Policy</a> and <a target="_blank" href="/terms-and-conditions.html">Terms</a></p>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalRegister;
