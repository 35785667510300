import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {getFunctions} from "firebase/functions";

import "./CreateChat.scss"
import {AppDispatch, RootState} from "../../../redux/Store";

import {Firebase} from "../../../app/App";
import {fetchUserChatId, fetchUserChatsIds} from "../../../redux/AppSlice";

interface modalShowInterface {
    show: boolean,
    setShow: (value: boolean) => void,
    setLoadingDataUser:(value: boolean) => void,
    loadingDataUser:boolean
}

const CreateChat = ({show, setShow,loadingDataUser,setLoadingDataUser}: modalShowInterface) => {
    const dispatch: AppDispatch = useDispatch();

    const [nameChat, setNameChat] = useState('');

    const chatsIds: any = useSelector(
        (state: RootState) => state.app.userChats
    );

    const [createChat] = useHttpsCallable(
        getFunctions(Firebase),
        'createChat'
    )

    const handleClose = (): void => {
        if (chatsIds.length) {
            setShow(false);
        }
    }

    const submitCreateChat = async () => {
           if ( !loadingDataUser && nameChat.trim().length) {
               createChat({chat_title:nameChat}).then(res => {
                   let chatsData: any = res?.data
                   if (chatsData) {
                       const newChat = {
                           user_id: chatsData.chat.user_id,
                           created_at: chatsData.chat.created_at,
                           title: chatsData.chat.title,
                           id: chatsData.firstMessage.chat_id
                       }
                       dispatch(fetchUserChatsIds([newChat,...chatsIds]))
                       dispatch(fetchUserChatId(newChat.id))
                   }
               })
               setLoadingDataUser(true)
               setShow(false);
           }
    }

    const handleKeyPress = (event:any) => {
        if (event.key === 'Enter' && nameChat.trim().length) {
            submitCreateChat();
        }
    };

    return (
        <Modal  className='CreateChat' show={show} centered={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create Chat</Modal.Title>
            </Modal.Header>
             <Modal.Body>
                 <form action="" method="post">
                     <div className="input-item">
                         <label htmlFor="idea">CHAT NAME</label>
                         <input type="text" onKeyDown={handleKeyPress} value={nameChat} onChange={(e) => setNameChat(e.currentTarget.value)} required placeholder="Enter chat name*"/>
                     </div>
                 </form>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={submitCreateChat} disabled={!nameChat.trim().length}  type="submit" className='button-submit' >
                    CREATE NEW CHAT
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateChat;
