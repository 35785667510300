import React, {useEffect, useState} from 'react';
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {getFunctions} from "firebase/functions";
import {getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup} from "firebase/auth";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import './Login.scss';
import {AppDispatch} from "../../redux/Store";
import {fetchAllMessage, fetchProvider, fetchUserChatId, fetchUserChatsIds} from "../../redux/AppSlice";
import ModalRegister from "../../components/UI/ModalRegister/ModalRegister";

import {Firebase} from "../../app/App";
import Loader from "../../components/UI/Loader/Loader";

const Login = () => {
    const providerGoogle = new GoogleAuthProvider();
    const providerApple = new OAuthProvider('apple.com');
    const auth = getAuth();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showModalRegister, setShowModalRegister] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [getCurrentUser] = useHttpsCallable(
        getFunctions(Firebase),
        'getCurrentUser'
    )

    const signGoogle = async () => {
      const tryLogin =  await signInWithPopup(auth, providerGoogle)
            .then((result) => {
                const credential: any = GoogleAuthProvider.credentialFromResult(result);
                setLoading(true)
                credential && dispatch(fetchProvider('Google'))
                return true
            })
            .catch((error) => {
               console.log(error)
                return false
            });

      if (tryLogin) {
          const isUser = await getCurrentUser().then((res) => {
              return res
          })

          if (!isUser) {
              setShowModalRegister(true)
              setLoading(false)
          }

          if (isUser) {
              setLoading(false)
              navigate('/main')
          }
      }
    }

    const signApple = async () => {
        await signInWithPopup(auth, providerApple)
            .then((result) => {
                const user = result.user;
                console.log(user)
                const credential = OAuthProvider.credentialFromResult(result);
                credential && dispatch(fetchProvider('Apple'))
            })
            .catch((error) => {
               console.log(error)
            });
    }

    useEffect(() => {
        dispatch(fetchUserChatId(''))
        dispatch(fetchAllMessage([]))
        dispatch(fetchUserChatsIds([]))
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {loading ? <Loader/>:
            <div className='login-screen'>
                <a target="_blank" href="https://octy.dev/">
                    <img src={"./images/logo.svg"} className="logo" alt="Logo"/>
                </a>
                <div className='login-screen-container'>
                    <div className='login-screen-container-top'>
                        <h1>Hone Your <br/> Startup {windowWidth > 1030 ? <br/> : null} Pitching Skills with AI Investor
                        </h1>
                        <img src={"./images/login-img.png"}/>
                    </div>
                    <div className='login-screen-container-main'>
                        <p>Welcome to Octy Pitch Practice AI, the premier platform for startup founders to refine their
                            pitch-perfect prowess. With our cutting-edge AI-powered simulation, you can fine-tune your
                            presentation skills in a risk-free environment. Get ready to impress potential investors
                            with
                            confidence and conviction. Elevate your pitch game today!</p>
                        <a onClick={signGoogle} className='button-login'>SIGN IN WITH GOOGLE<img
                            src={"./images/google-icon.svg"}/></a>
                    </div>
                </div>
                <div className="footer">
                    <div className="social-links">
                        <a target="_blank" href="https://www.linkedin.com/company/octy/">
                            <img className="icon"
                                 src={"./images/linkedIn-icon.svg"}
                                 alt="linkedIn"/>
                        </a>
                        <a target="_blank" href="https://twitter.com/octy_dev">
                            <img className="icon"
                                 src={"./images/twitter-icon.svg"}
                                 alt="twitter"/>
                        </a>
                        <a target="_blank" href="https://octy.dev/">
                            <img className="icon"
                                 src={"./images/Octy-icon.svg"}
                                 alt="OCTY"/>
                        </a>
                    </div>
                    <p>2023 octy inc. All rights reserved</p>
                </div>
            </div>}
            {showModalRegister ? <ModalRegister setLoading={setLoading} show={showModalRegister} setShow={setShowModalRegister}/> : null}
        </>
    );
};

export default Login;
