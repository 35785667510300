import React from 'react';
import './ItemMessage.scss';
import {Message} from "../../../redux/AppSlice";
import LoadingMessage from "../LoadingMessage/LoadingMessage";
import AnimatedText from "../../Animation/LetterByLetter";

const ItemMessage = ({message, type, id,index,countAllMessages,playingTextAnimation,setPlayingTextAnimation}: Message | any) => {

    return (
        <div key={id} className='item-message' style={{justifyContent: type ? "flex-end" : "flex-start"}}>

            <div className={type ? 'item-message-user' : 'item-message-chat'}>
                {!message ?
                    <LoadingMessage/>
                    : index + 1 === countAllMessages && !type && playingTextAnimation ?
                        <AnimatedText setPlayingTextAnimation={setPlayingTextAnimation} text={message}/> :
                        <p>{message}</p>}</div>
        </div>
    );
};

export default ItemMessage;
