import React, {useEffect} from "react";
import {redirect, Route, Routes, useNavigate} from "react-router-dom";

import Main from "./pages/Main/Main";
import Login from "./pages/Login/Login";
import {getAuth} from "firebase/auth";
import {useAuthState} from "react-firebase-hooks/auth";
import {Firebase} from "./app/App";

export const useRoutes = () => {
    const auth = getAuth(Firebase);

    const [user, loading, error] = useAuthState(auth)

    return <Routes>
        <Route path="/main" element={<Main/>}/>
        <Route path="/" element={<Login/>}/>
    </Routes>

}

