import {createSlice} from '@reduxjs/toolkit';

export type Message = {
  message: string,
  type:number,
  id:string,
}

export type Chat = {
  created_at:
      {_seconds: number, _nanoseconds: number},
  id: string,
  title: string,
  user_id:string
}

interface State {
  messages: [] | Message[] | any ;
  isLoading: boolean;
  error: string | null;
  provider: string;
  userChats: Chat[] | null | [];
  userChatId: string;
}

const initialState: State = {
  messages: [],
  isLoading:false,
  error: null,
  provider:'',
  userChats: [],
  userChatId: '',
};

const AppSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    fetchMessageLocal: (state, action) => {
      state.messages.push(action.payload)
    },
    fetchAllMessage: (state, action) => {
      state.messages = action.payload
    },
    fetchProvider: (state, action) => {
      state.provider = action.payload
    },
    fetchUserChatsIds: (state, action) => {
      state.userChats = action.payload
    },
    fetchUserChatId: (state, action) => {
      state.userChatId = action.payload
    },
    deleteChatLocal: (state: any, action: any ) => {
      state.userChats = state.userChats.filter((item: any) => item.id !== action.payload)
    },
    updateMessage: (state, action) => {
      const {id, updatedData} = action.payload;
      const index = state.messages.findIndex((obj: Message) => obj.id === id);
      if (index !== -1) {
        state.messages[index] = {...state.messages[index], ...updatedData};
      }
    },
  },
});

export const {
  fetchMessageLocal,
  fetchProvider,
  fetchUserChatsIds,
  fetchUserChatId,
  fetchAllMessage,
  updateMessage,
  deleteChatLocal
} = AppSlice.actions;

export default AppSlice.reducer;
