import React, { useState, useEffect } from 'react';

const AnimatedText = (props:any) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    let i = 0;
    const intervalId = setInterval(() => {
      setDisplayText((prevDisplayText) => prevDisplayText + ' ' + props.text.split(' ')[i]);
      i++;
      if (i === props.text.split(' ').length){
        clearInterval(intervalId)
        props.setPlayingTextAnimation(false)
      };
    }, 150);

    return () => clearInterval(intervalId);
  }, [props.text]);

  return <p>{displayText.trim()}</p>;
};

export default AnimatedText;
