import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {getFunctions} from "firebase/functions";
import {useAuthState} from "react-firebase-hooks/auth";
import {Container, Nav, Navbar, Offcanvas} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";

import './Main.scss';
import ItemMessage from "../../components/UI/ItemMessage/ItemMessage";
import {
    fetchAllMessage,
    fetchMessageLocal,
    fetchUserChatId,
    fetchUserChatsIds,
    Message,
    updateMessage
} from "../../redux/AppSlice";
import {AppDispatch, RootState} from "../../redux/Store";
import {getAuth, signOut} from "firebase/auth";
import runOnce from "../../hooks";
import Loader from "../../components/UI/Loader/Loader";

import {Firebase} from "../../app/App";
import ModalDeleteChat from "../../components/UI/ModalDeleteChat/ModalDeleteChat";
import CreateChat from "../../components/UI/CreateChat/CreateChat";

const Main = () => {
    const [message, setMessage] = useState('');
    const [userName, setUserName] = useState('');
    const [loadingDataUser, setLoadingDataUser] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(false);
    const [playingTextAnimation, setPlayingTextAnimation] = useState(false);
    const [showModalDeleteChat, setShowModalDeleteChat] = useState(false);
    const [showModalCreateChat, setShowModalCreateChat] = useState(false);

    const dispatch: AppDispatch = useDispatch();
    const arrayMessagesRef: any = useRef(null);
    const arrayNavigationRef: any = useRef(null);
    const inputRef: any = useRef(null);
    const auth = getAuth(Firebase);
    const navigate = useNavigate();

    const [user, loading, error] = useAuthState(auth)

    const arrayMessages = useSelector(
        (state: RootState) => state.app.messages
    );


    const chatsIds: any = useSelector(
        (state: RootState) => state.app.userChats
    );

    const selectedChatId: any = useSelector(
        (state: RootState) => state.app.userChatId
    );

    const [getCurrentUser] = useHttpsCallable(
        getFunctions(Firebase),
        'getCurrentUser'
    )

    const [getCurrentUserChats] = useHttpsCallable(
        getFunctions(Firebase),
        'getCurrentUserChats'
    )

    const [getChatMessages] = useHttpsCallable(
        getFunctions(Firebase),
        'getChatMessages'
    )

    const [sendMessage] = useHttpsCallable(
        getFunctions(Firebase),
        'sendMessage'
    )

    const [createChat] = useHttpsCallable(
        getFunctions(Firebase),
        'createChat'
    )

    const handleChange = (event: any) => {

        inputRef.current.rows = 1;

        const textareaLineHeight = 26;
        const currentRows = Math.floor(event.target.scrollHeight / textareaLineHeight);
        let newRows = Math.min(4, currentRows);

        inputRef.current.rows = newRows;

        setMessage(event.target.value);
    };


    const handleSendMessage = async () => {

        if (!selectedChatId && message.trim().length && !playingTextAnimation && !loadingMessage && !loadingDataUser && !loading) {
            createUserData();
        }

        if (message.trim().length && selectedChatId && !playingTextAnimation && !loadingMessage && !loadingDataUser && !loading) {
            dispatch(fetchMessageLocal({
                id: Math.random(),
                type: 1,
                message: message
            }))

            setMessage('')
            inputRef.current.rows = 1;

            setLoadingMessage(true)
            setTimeout(() => {
                dispatch(fetchMessageLocal({
                    id: 'animation',
                    type: 0,
                    message: '',
                }))
            }, 1500);

            await sendMessage({message: message, chat_id: selectedChatId}).then((response: any) => {
                const message = response.data.message.message

                if (Array.isArray(message)) {
                    message.join(', ')
                }

                dispatch(updateMessage({
                    id: 'animation', updatedData: {
                        id: response.data.message.id,
                        type: response.data.message.type,
                        message: message
                    }
                }))

                setLoadingMessage(false)
                setPlayingTextAnimation(true)

            }).catch(error => {
                console.log(error, 'error sendMessage');
                dispatch(fetchMessageLocal({
                    id: error,
                    type: 0,
                    message: error
                }))
            });
        }
    };

    const handleKeyPress = (event:any) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };

    const scrollToLastMessage = () => {
        const lastChildElement = arrayMessagesRef.current?.lastElementChild;
        if (lastChildElement) {
            setTimeout(() => {
                lastChildElement.scrollIntoView({behavior: 'smooth'});
            }, 500);
        }
    };

    const scrollToSelectedNavItem = () => {
        const selectedElement = arrayNavigationRef.current?.querySelector('.main-screen-list-chats-item-selected');
        if (selectedElement) {
            setTimeout(() => {
                selectedElement.scrollIntoView({behavior: 'smooth'});
            }, 500);
        }
    };

    const disableFocusOnInput = () => {
        inputRef.current.blur();
    }

    const addFocusOnInput = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (inputRef.current && !loadingDataUser) {
            inputRef.current.focus();
        }
    };


    const createUserData = runOnce(async () => {
        setShowModalCreateChat(true)
    });

    const createFirstUserChat = runOnce(async () => {
        createChat({chat_title: "Intro"}).then(res => {
            let chatsData: any = res?.data
            if (chatsData) {
                const newChat = {
                    user_id: chatsData.chat.user_id,
                    created_at: chatsData.chat.created_at,
                    title: chatsData.chat.title,
                    id: chatsData.firstMessage.chat_id
                }
                dispatch(fetchUserChatsIds([newChat, ...chatsIds]))
                dispatch(fetchUserChatId(newChat.id))
            }
        })
    });

    const getUserData = runOnce(async () => {
        setLoadingDataUser(true)
        const res = await getCurrentUser();

        if (res) {
            let userData: any = res?.data
            setUserName(userData.user.name)

            await getCurrentUserChats().then(result => {
                let chatsData: any = result?.data

                if (chatsData.chats.length > 1) {
                    dispatch(fetchUserChatsIds(chatsData.chats))
                    dispatch(fetchUserChatId(chatsData.chats[0].id))
                } else  {
                    createFirstUserChat()
                }
            })
        }
    });

    const getChatMessagesData = runOnce(async () => {
        setLoadingDataUser(true)
        await getChatMessages({chat_id: selectedChatId}).then((res: any) => {
            if (res) {
                let messagesData: any = res.data
                let messages = messagesData.messages.reverse()
                dispatch(fetchAllMessage(messages))
                setTimeout(() => {
                    setLoadingDataUser(false)
                }, 500);
            }
        });
    });

    const logOut = () => {
        signOut(auth).then(() => {
            navigate('/')
        }).catch((error) => {
            console.log(error)
        });
    }

    const deleteChat = () => {
        setShowModalDeleteChat(true)
    }

    const selectChat = (chat: any) => {
        dispatch(fetchUserChatId(chat.id))
    }

    const createNewChat = () => {
        createUserData()
    }

    useEffect(() => {
        if (!user) {
            navigate('/')
        }
        getUserData();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            scrollToLastMessage()
        }, 50);
    }, [arrayMessages]);

    useEffect(() => {
        dispatch(fetchAllMessage([]))
        selectedChatId && getChatMessagesData()
        setMessage('')
        setPlayingTextAnimation(false)
        setTimeout(() => {
            scrollToLastMessage()
        }, 500);
        disableFocusOnInput()
    }, [selectedChatId]);

    useEffect(() => {
        setTimeout(() => {
            scrollToSelectedNavItem()
        }, 50);
    }, [chatsIds]);

    useEffect(() => {
        if (!playingTextAnimation) {
            setTimeout(() => {
                scrollToLastMessage()
            }, 50);
        }
    }, [playingTextAnimation]);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        function handleResize() {
            setWindowHeight(window.innerHeight);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
        <>
            <div className='main-screen'>
                <div className='main-screen-list-chats'>
                    <h2 className='list-chats-title'>Your <br/> chats</h2>
                    <div className='nav-container'ref={arrayNavigationRef} >
                            {chatsIds.map((el: any) => {
                                return <a key={el.id} onClick={() => selectChat(el)}
                                          className={selectedChatId === el.id ? 'main-screen-list-chats-item-selected' : 'main-screen-list-chats-item'}>
                                    <div>
                                        <img className='icon-chat'
                                             src={selectedChatId === el.id ? './images/icon-chat-selected.svg' : './images/chat-icon.svg'}/>
                                        {el.title}
                                    </div>
                                    {/*{chatsIds.length  > 1 && <img className='delete-chat-icon' onClick={deleteChat}*/}
                                    {/*                              src={selectedChatId === el.id  ? './images/delete-icon.svg' : undefined}/>}*/}
                                </a>
                            })}
                    </div>
                    <div>
                        <a onClick={createNewChat}
                           className={loadingDataUser ? 'main-screen-list-chats-buttonAddDisabled' : 'main-screen-list-chats-buttonAdd'}>new
                            chat</a>
                        <div className='user-menu'>
                            <span>{userName.slice(0, 1)}</span>
                            <p>{userName}</p>
                            <img onClick={logOut} alt='log-out' src={'./images/logOut-icon.svg'}/>
                        </div>
                        <div className='privacy-terms'>
                            <a target="_blank" href="/privacy-policy.html">Privacy Policy</a>
                            <a target="_blank" href="/terms-and-conditions.html">Terms</a>
                        </div>
                    </div>
                </div>
                <div className='nav-mobile'>
                    <Navbar variant='light' key='lg' expand='lg' className="bg-body-tertiary">
                        <Container fluid>
                            <Navbar.Toggle />
                            <Navbar.Offcanvas
                                placement="start"
                            >
                                <Offcanvas.Header closeButton closeVariant='white'>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                                        Your chats
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Nav className="flex-grow-1 ">
                                        <div className='nav-container' ref={arrayNavigationRef}>
                                            {chatsIds.map((el: any) => {
                                                return <a key={el.id} onClick={() => selectChat(el)}
                                                          className={selectedChatId === el.id ? 'main-screen-list-chats-item-selected' : 'main-screen-list-chats-item'}>
                                                    <div>
                                                        <img className='icon-chat'
                                                             src={selectedChatId === el.id ? './images/icon-chat-selected.svg' : './images/chat-icon.svg'}/>
                                                        {el.title}
                                                    </div>
                                                {/*    {chatsIds.length > 1 && <img className='delete-chat-icon' onClick={deleteChat}*/}
                                                {/*                                    src={selectedChatId === el.id  ? './images/delete-icon.svg' : undefined}/>}*/}
                                                </a>
                                            })}
                                        </div>
                                        <div>
                                            <a onClick={createNewChat}
                                               className={loadingDataUser ? 'main-screen-list-chats-buttonAddDisabled' : 'main-screen-list-chats-buttonAdd'}>new
                                                chat</a>
                                            <div className='user-menu'>
                                                <span>{userName.slice(0, 1)}</span>
                                                <p>{userName}</p>
                                                <img onClick={logOut} alt='log-out' src={'./images/logOut-icon.svg'}/>
                                            </div>
                                            <div className='privacy-terms'>
                                                <a target="_blank" href="/privacy-policy.html">Privacy Policy</a>
                                                <a target="_blank" href="/terms-and-conditions.html">Terms</a>
                                            </div>
                                        </div>
                                    </Nav>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                </div>
                <div className='chat-container' style={loading || loadingDataUser ? {
                    justifyContent: 'flex-end',
                    paddingBottom: windowHeight / 29
                } : {justifyContent: 'flex-start',
                    paddingBottom: windowHeight / 29}}>
                    {loading || loadingDataUser ? <Loader/> : <div className='chat-container-chat'>
                        <div className='chat-container-chat-item'>
                            <div className='container' ref={arrayMessagesRef} onClick={disableFocusOnInput}>
                                {arrayMessages.map((el: Message, index: number) => {
                                    return (<ItemMessage
                                        index={index}
                                        countAllMessages={arrayMessages.length}
                                        id={el.id}
                                        message={el.message}
                                        playingTextAnimation={playingTextAnimation}
                                        setPlayingTextAnimation={setPlayingTextAnimation}
                                        type={el.type}/>)
                                })}
                            </div>
                        </div>
                    </div>}
                    <div className='chat-container-input'>
                        <div onClick={addFocusOnInput}>
                            <textarea
                                rows={1}
                                onChange={handleChange}
                                ref={inputRef}
                                disabled={loadingDataUser}
                                onKeyDown={handleKeyPress}
                                autoFocus={true}
                                placeholder='Enter message'
                                value={message}/>
                            <svg className='icon' onKeyDown={handleKeyPress}
                                 onClick={handleSendMessage}
                                 focusable="false"
                                 fill={!message.trim().length ? '#868686' : '#7B67F2'}
                                 viewBox="0 0 24 24">
                                <path d="M2.01 21 23 12 2.01 3 2 10l15 2-15 2z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            {showModalDeleteChat ? <ModalDeleteChat show={showModalDeleteChat} setShow={setShowModalDeleteChat}
                                                    chatId={selectedChatId}/> : null}
            {showModalCreateChat ? <CreateChat show={showModalCreateChat} loadingDataUser={loadingDataUser}
                                               setLoadingDataUser={setLoadingDataUser}
                                               setShow={setShowModalCreateChat}/> : null}

        </>
    );
};

export default Main;
